* {
  font-family: 'Roboto', sans-serif;
}

*:focus {
  outline: none;
}

p {
  color: orange;
}

.orange {
  color: orange;
}

input.form-control {
  padding: 0;
  border: none;
  font-size: 30px;
  color: #ffa500 !important;
}

button.time-zone {
  padding: 0;
  border: none;
  color: orange;
  outline: none;
}

input.form-control:focus {
  box-shadow: none;
}

input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}

input::-webkit-date-and-time-value {
  text-align: left;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
